import { endsWith } from "./css-utils";

/**
 * Gets the current page type
 */
export function getPageType() {
  if (window.location.href.toLowerCase().indexOf("/products/") > -1)
    return "product";
  if (window.location.href.toLowerCase().indexOf("/collection") > -1)
    return "collection";
  if (window.location.href.toLowerCase().indexOf("/collections") > -1)
    return "collections";
  if (window.location.href.toLowerCase().indexOf("/search") > -1)
    return "search";
  if (window.location.href.toLowerCase().indexOf("/pages/") > -1)
    return "page";
  if (window.location.href.toLowerCase().indexOf("/cart") > -1) return "cart";
    
  // Check if we're on homepage (URL is just domain with optional locale prefix and trailing slash)
  const url = new URL(window.location.href);
  const pathSegments = url.pathname.split('/').filter(segment => segment); // Remove empty segments

  // Check if path is empty or just contains a locale identifier
  if (pathSegments.length === 0 || 
      (pathSegments.length === 1 && /^[a-z]{2}(-[a-z]{2})?$/i.test(pathSegments[0]))) {
    return "home";
  }

  return null;
}

/**
 * Check if running in theme editor
 */
export function isThemeEditor() {
  return window.location.href.toLowerCase().indexOf("design_theme_id") > -1;
}


/**
 * Find Countdown Holder Element
 */
export function findCountdownHolderElm(id = "#ct_pe_disco_holder") {
  var countdownHolder = document.querySelector(id);

  if(countdownHolder)
    return countdownHolder;
  
  return null;
}


/**
 * Find Countdown Holder Element for specific sale
 */
export function findCountdownHolderElmForSaleId(saleId) {
  var countdownHolder = document.querySelector(`#ct_pe_disco_holder_${saleId}`);

  if(countdownHolder)
    return countdownHolder;
  
  return null;
}


/**
 * Checks if countdown is already integrated
 */
export function isCountdownIntegrated() {
  var timerElement =  document.getElementById("ct_pe_disco");
  
  return timerElement !== null;
}

/**
 * Checks if saletag is already integrated
 */
 export function isSaleTagIntegrated() {
  var saleTagElements =  document.getElementsByClassName("pe-disco-sale-tag__holder");
  
  return saleTagElements.length > 0;
}


/**
 * Checks if announcement bar is already integrated
 */
export function isAnnouncementBarIntegrated() {
  var barElm = document.getElementById("pe-announcement-bar");
  return barElm !== null;
}


/**
 * Finds the AddToCart form element.
 */
export function findAddToCartElement() {
  var selectors = [];
  var windowWidth = window.innerWidth || document.body.clientWidth;

  if (windowWidth <= 768)
    selectors.push(
      '.product-single .lg--hide #AddToCartForm--product-template form[action="/cart/add"]'
    );

  if (windowWidth > 768)
    selectors.push(
      '.section--wrapper .dt--one-third #AddToCartForm--product-template form[action="/cart/add"]'
    );

  selectors.push(
    'div[data-shopify="payment-button"]',
    ".shopify-payment-button",
    "form#AddToCartForm div.cart_qty",
    "button#AddToCart",
    "#CartButtons-product-template",
    "button.product-single__add-btn",
    ".product_payments_btns",
    "input#addToCart",
    "#AddToCart--product-template",
    ".product-form-atc.product-form-atc-spb",
    "form.product-form-nojs",
    "#product-form-page-product span.payment-buttons-container",
    ".quadd-wrapper--with-payment-button .product-add",
    "form.shopify-product-form .smart-payment-button--true",
    "form#AddToCartForm .paymentButtonsWrapper",
    'form[action="/cart/add"] div.details_wrapper div.details_right div.social_sharing_block',
    'form[action="/cart/add"] div.product-form-add-to-cart',
    ".product-form__item--payment-button",
    ".product-shop .total-price + .groups-btn",
    ".cta-content.cta-content--pay-buttons",
    ".product-form__payment-container",
    ".prd-block_actions.topline",
    "div.product-smart-wrapper",
    "form .product-add-to-cart",
    '.omega .product_form form[action="/cart/add"]',
    ".template--product .layout--main-content .add-to-cart-panel button.add",
    "form div.product-form",
    ".klearfix div#newaddtocart",
    "form#addToCartForm-product-template .payment-buttons",
    "#product-add",
    'form[action="/cart/add"]',
    'form[enctype="multipart/form-data"]',
    "form#addToCartForm",
    'form[action="/cart/add.js"]',
    ".ProductForm__AddToCart",
    "form[data-product-form]",
    "a.btn-addtocart.addtocart-js",
    ".addToCartWrapper",
    "form#AddToCartForm",
    "form#AddToCartForm--product-template",
    "form.product-form",
    "form#add-to-cart-form",
    "form.product-form-product-template",
    "form.AddToCartForm",
    "form.add-to-cart__form",
    "form.product-single__form",
    "form.productForm",
    "form.form-add-to-cart",
    "form.product-page--cart-form",
    "form.product__form",
    "form.js-product-form",
    "form#AddToCartForm-product-sections-template",
    "form#AddToCartForm-product-template",
    ".product-block.product-block--price"
  );

  for (var i = 0; i < selectors.length; i++) {
    var elms = document.querySelectorAll(selectors[i]);
    if (elms)
      for (var j = 0; j < elms.length; j++)
        if (!parentIsSpecialCase(elms[j])) return elms[j];
  }

  return null;
}

/**
 * Checks if an element is a special case by going through parents.
 * @param {Element} elm
 */
export function parentIsSpecialCase(elm) {
  var specialCases = [
    "#shopify-section-header",
    ".sidebar",
    ".sticky-element .product-detail__adjacent",
    ".mfp-container",
    ".modal .modal-body",
    ".quick-smart-wrapper",
    ".modal.quick-view"
  ];
  // Check if elm's parents are one of above classes or id's.
  for (var i = 0; i < specialCases.length; i++) {
    if (elm.closest(specialCases[i])) return true;
  }
  return false;
}

/**
 * Finds all product image wrapper elements on collection page.
 */
export function findAllProductImagesOnCollectionPage() {
  return document.querySelectorAll(`a[href*="/products/"]`);
}

/**
 * Finds the sale tag placeholder element.
 * @param {string} handle 
 * @returns 
 */
export function findSaleTagPlaceholder(handle) {
  const selection = document.querySelectorAll(`div[sale-tag-slug$="${encodeURIComponent(handle)}"]`);
  let holder = selection.length > 0 ? selection[0] : null;
  return holder;
}

/**
 * Checks if there are any sale tag placeholder elements.
 * @returns 
 */
export function anySaleTagPlaceholder() {
  const allDivsWithSaleTagSlug = document.querySelectorAll('div[sale-tag-slug]');
  return allDivsWithSaleTagSlug.length > 0;
}

/**
 * Checks if a element is hidden.
 * @param {Element} el 
 * @returns 
 */
export function isHidden(el) {
  return (el.offsetParent === null)
}

/**
 * Checks if an element has a parent with a given class.
 * @param {Element} element 
 * @param {string} className 
 * @param {number} generations 
 * @returns 
 */
export function hasParentWithClass(element, className, generations = 6) {
  // Guard against null/undefined element or className
  if (!element || !className) {
      return false;
  }
  
  let currentElement = element;
  let currentGeneration = 0;
  
  while (currentElement && currentGeneration < generations) {
      // Safely check if classList exists and contains method is available
      if (currentElement.classList?.contains?.(className)) {
          return true;
      }
      
      // Get next parent, might be null/undefined which will end the loop
      currentElement = currentElement?.parentElement || null;
      currentGeneration++;
  }
  
  return false;
}

/**
 * Finds product image wrapper by handle on collection page.
 */
export function findProductImageOnCollectionPage(handle) {
  const productHref = `/products/${encodeURIComponent(handle)}`;
  let selection = document.querySelectorAll(`a[href$="${productHref}"]`);
  if (selection.length === 0) {
    selection = document.querySelectorAll(`a[href*="${productHref}"]`);
  }

  const navigationClasses = ["site-nav--link", "site-nav__link", "site-nav", "header__menu-item", "list-menu__item", "link--text", "nav--link", "mobile-nav", "mega-menu__link", "menu-drawer__menu-item", "mobile-menu-slider-img", "mobile-menu-slider-item", "header__menu-item", "list-menu__item", "t4s-menu-item"];

  // Try each anchor tag until we find a valid one
  for (let i = 0; i < selection.length; i++) {
    const element = selection[i];
    const isNavLink = navigationClasses.some(x => 
      element.className.indexOf(x) !== -1 || 
      hasParentWithClass(element, x)
    );

    // Skip navigation links
    if (isNavLink) continue;

    // Check if this anchor tag has valid images
    const childImgs = element.getElementsByTagName('img');
    if (childImgs.length > 0 && !isHidden(childImgs[childImgs.length - 1])) {
      return childImgs[0];
    }
    
    const classes = [".card__media .media", ".product-image.pr .db .pr_lazy_img.main-img", ".product__media__image", ".grid-product__image-mask .grid__image-ratio"];

    for (const className of classes) {
      const specialCasesImgs = findDescendantsWithClass(element, className, true);
      if (specialCasesImgs.length > 0) {
        return specialCasesImgs[specialCasesImgs.length - 1];
      }
    }
    
    const parentChildImgs = findDescendantsWithClass(element, "img", true);
    if (parentChildImgs.length > 0) {
      const lca = findLowestCommonAncestor(parentChildImgs);
      const broadSelectors = [
        'page-container',
        'main-content', 
        'MainContent', 
        'body-content-wrapper',
        'wrapper-body'
      ];
      
      // If LCA is valid, return it
      if (lca && 
          lca.tagName.toUpperCase() !== 'BODY' && 
          !broadSelectors.some(selector => 
            lca.classList.contains(selector) || lca.id === selector
          )) {
        return lca;
      }
    }
  }

  // If no valid images found in any anchor tag, return the first non-navigation anchor or null
  for (let i = 0; i < selection.length; i++) {
    const element = selection[i];
    const isNavLink = navigationClasses.some(x => 
      element.className.indexOf(x) !== -1 || 
      element.parentElement.className.indexOf(x) !== -1
    );
    if (!isNavLink) return element;
  }

  return null;
}

/**
 * Gets all ancestors or a node
 * @param {NodeList} nodeList
 */
function getAncestors(elem) {
  let ancestors = [];
  while (elem) {
    ancestors.push(elem);
    elem = elem.parentElement;
  }
  return ancestors;
}

/**
 * Finds lowest common ancestor
 * @param {NodeList} nodeList
 */
export function findLowestCommonAncestor(nodeList) {
  // Convert NodeList to an array of elements
  let elements = Array.from(nodeList);

  // Get all ancestors for each element
  let ancestorLists = elements.map(getAncestors);

  // Initialize the LCA to null
  let lca = null;

  // Iterate from the root down to the leaves
  let shortestList = ancestorLists.reduce((a, b) => a.length < b.length ? a : b);

  for (let i = 0; i < shortestList.length; i++) {
    let currentAncestor = shortestList[i];

    // Check if currentAncestor is an ancestor of all elements
    if (ancestorLists.every(ancestors => ancestors.includes(currentAncestor))) {
      lca = currentAncestor;
      break; // We've found the LCA, exit the loop
    }
  }

  return lca;
}

/**
 * Inserts a node into parent of referenceNode after that node
 * @param {Node} newNode
 * @param {Node} referenceNode
 */
export function insertAfter(newNode, referenceNode) {
  if (referenceNode && newNode)
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
}

/**
 * Adds a stylesheet to the head
 * @param {string} fileName
 */
export function addCss(fileName) {
  let createAndAppendLink = () => {
    var head = document.head;

    var link = document.createElement("link");
    link.type = "text/css";
    link.rel = "stylesheet";
    link.href = fileName;
  
    head.appendChild(link);

    return link;
  }

  if(isThemeEditor()) {
    var result;
    
    document.addEventListener("DOMContentLoaded", e => {
      result = createAndAppendLink();
    });

    return result;
  }
  else {
    return createAndAppendLink();
  }
}

/**
 * Adds a font import to the head
 * @param {string} fontFamily
 */
 export function importGoogleFont(fontFamily) {
  if (fontFamily) {
    let fontUrl = `"//fonts.googleapis.com/css?family=${fontFamily}:300,400,600,700&amp;lang=en"`
    let createAndAppendStyle = () => {
      var head = document.head;
  
      var style = document.createElement("style");
      style.innerHTML = `@import url(${fontUrl})`
  
      head.appendChild(style);
  
      return style;
    }
  
    if(isThemeEditor()) {
      var result;
      
      document.addEventListener("DOMContentLoaded", e => {
        result = createAndAppendStyle();
      });
  
      return result;
    }
    else {
      return createAndAppendStyle();
    }
  }
}

/**
 * Adds a script to the head
 * @param {string} fileName
 */
export function addScript(fileName) {
  let createAndAppendScript = () => {
    var head = document.head;

    var script = document.createElement("script");
    script.src = fileName;
  
    head.appendChild(script);
  }

  if(isThemeEditor()) {
    document.addEventListener("DOMContentLoaded", e => {
      createAndAppendScript();
    });
  }
  else {
    createAndAppendScript();
  }
}

/**
 * Finds all the descendants of the parents of an element that have a given CSS class.
 *
 * @param {Element} element - The element to start the search from.
 * @param {string} className - The CSS class name to search for.
 * @returns {Element[]} An array of elements that have the given CSS class and are descendants of the parents of the given element.
 */
export function findDescendantsWithClass(element, className, isHtmlTag = false) {
  // If the element has no parent, return an empty array
  if (!element.parentElement) {
    return [];
  }

  let result = [];
  
  // Find all the descendants of the parent element that have the given CSS class
  const classNames = className instanceof Array ? className : [className];
  for (let i = 0; i < classNames.length; i++) {
    const currentClassName = classNames[i];
    const selector = isHtmlTag ? currentClassName : '.' + currentClassName;
    const descendantsWithClass = element.parentElement.querySelectorAll(selector);

    // If any such descendants were found, return them
    if (descendantsWithClass.length > 0) {
      result = descendantsWithClass;
    } else {
      // If no such descendants were found, call the function again with the parent as the element
      result = findDescendantsWithClass(element.parentElement, currentClassName, isHtmlTag);
    }

    if (result && result.length > 0)
      break;
  }
  
  return result;
}

/**
 * Creates a div element.
 * @param {HTMLElement} innerHtml 
 */
 export function divElm(innerHtml = "", addClass = "") {
  const el = document.createElement("div");
  el.innerHTML = innerHtml;
  el.className += `${addClass}`;
  return el;
}

/**
 * Is browser IE? Returns version or false.
 */
export function isIE() {
  var myNav = navigator.userAgent.toLowerCase();
  return (myNav.indexOf('msie') != -1) ? parseInt(myNav.split('msie')[1]) : false;
}

/**
 * Logs a message to console if window.disco.DEBUG is true.
 * @param {string} msg 
 */
export function debugLog(msg) {
  if (window.disco && window.disco.DEBUG) console.log(msg)
}

/**
 * Checks if customer has been posted.
 */
export function checkIfCustomerPosted() {
  const params = new URLSearchParams(window.location.search);
  
  // Check if 'customer_posted' is set to 'true'
  return params.get("customer_posted") === "true" || params.get("form_type") === "customer";
}

/**
 * Polyfill for Element.prototype.closest, Object.entries, Array.prototype.find
 */
if (!Element.prototype.matches)
  Element.prototype.matches = Element.prototype.msMatchesSelector;
if (!Element.prototype.closest)
  Element.prototype.closest = function(selector) {
    var el = this;
    while (el) {
      if (el.matches(selector)) {
        return el;
      }
      el = el.parentElement;
    }
  };
if(!Object.entries)
  Object.entries = (obj) => Object.keys(obj).map(key => [key, obj[key]]);
if(!Object.values)
  Object.values = (obj) => Object.keys(obj).map((e) => obj[e]);
if (!Array.prototype.find) {
  Object.defineProperty(Array.prototype, 'find', {
    value: function(predicate) {
      // 1. Let O be ? ToObject(this value).
      if (this == null) {
        throw new TypeError('"this" is null or not defined');
      }

      var o = Object(this);

      // 2. Let len be ? ToLength(? Get(O, "length")).
      var len = o.length >>> 0;

      // 3. If IsCallable(predicate) is false, throw a TypeError exception.
      if (typeof predicate !== 'function') {
        throw new TypeError('predicate must be a function');
      }

      // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
      var thisArg = arguments[1];

      // 5. Let k be 0.
      var k = 0;

      // 6. Repeat, while k < len
      while (k < len) {
        // a. Let Pk be ! ToString(k).
        // b. Let kValue be ? Get(O, Pk).
        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
        // d. If testResult is true, return kValue.
        var kValue = o[k];
        if (predicate.call(thisArg, kValue, k, o)) {
          return kValue;
        }
        // e. Increase k by 1.
        k++;
      }

      // 7. Return undefined.
      return undefined;
    }
  });
}

/**
 * Checks if hero banner is integrated
 */
export function isHeroBannerIntegrated() {
  const bannerElement = document.querySelector('#pe-hero-banner-holder');
  return bannerElement && bannerElement.children.length > 0;
}

/**
 * Checks if a sale is in preview mode via URL parameter
 * @param {number} saleId The sale ID to check
 * @returns {boolean}
 */
export function isSaleInPreviewMode(saleId) {
    const urlParams = new URLSearchParams(window.location.search);
    const previewSaleId = urlParams.get('previewSale');
    return previewSaleId && previewSaleId === saleId.toString();
}

/**
 * Finds the preview sale
 * @param {array} sales 
 */
export function findPreviewSale(sales) {
  if (!sales || !sales.length) return null;
  return sales.find(x => isSaleInPreviewMode(x.Id));
}

/**
 * Determines if a sale should be treated as active (either naturally or via preview)
 * @param {object} sale The sale object to check
 * @returns {boolean} 
 */
export function isSaleActive(sale) {
    const isNaturallyActive = sale.Status === 1 || sale.Status === 2;
    const isInPreview = isSaleInPreviewMode(sale.Id);
    return isNaturallyActive || isInPreview;
}

export function observeProductAdditions(callback) {
  // Create an observer instance
  const observer = new MutationObserver((mutations) => {
    let shouldCallback = false;
    
    mutations.forEach((mutation) => {
      // Check if new nodes were added
      if (mutation.addedNodes.length) {
        // Look for product links in added nodes
        mutation.addedNodes.forEach((node) => {
          if (node.nodeType === 1) { // Element node
            const productLinks = node.querySelectorAll('a[href*="/products/"]');
            if (productLinks.length > 0) {
              shouldCallback = true;
            }
          }
        });
      }
    });

    if (shouldCallback) {
      // Delay callback slightly to ensure DOM is fully updated
      setTimeout(callback, 100);
    }
  });

  // Configure and start the observer
  const config = { 
    childList: true, 
    subtree: true 
  };
  
  observer.observe(document.body, config);
  return observer;
}
