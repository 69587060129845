import React, { useEffect, useState } from "react";
import {
  Page,
  Layout,
  Frame,
  Button,
  Card,
  BlockStack,
  Text,
  InlineStack,
  Box,
  Icon
} from "@shopify/polaris";
import {
  ClockMajor,
  StatusActiveMajor,
  DiscountsMajor
} from "@shopify/polaris-icons";
import OnboardingComponent from "../../features/Onboarding/OnboardingComponent";
import {
  buildAppEmbedDeepLink,
  buildAppBlockDeepLink,
  buildAppEmbedExpandDeepLink
} from "../../utils/ExtensionDeepLinkBuilder";
import { GET_SALE_STATISTICS } from "../../graphql/Queries/TaskStatus";
import { useQuery, useMutation } from "@apollo/client";
//import { GetPlanName } from "../Subscription/SubscriptionPlan";
import { GET_ONBOARDING_STATUS } from "../../graphql/Queries/Settings";
import { SAVE_ONBOARDING_STATUS } from "../../graphql/Mutations/Settings";
import "./DashboardPage.css";
import LinkButton from "../../components/LinkButton/LinkButton";
import CarouselCard from "../../components/Carousel/CarouselCard";
import HelpBanner from "../../components/Banners/HelpBanner";
import FeedbackCard from "../../components/Review/FeedbackCard";
import { openBeacon } from "../../utils/BeaconUtils";
import { useNavigate } from "react-router-dom";
import SaleTypeModal from "../../components/Modals/SaleTypeModal";
import { getSaleTypeUrlParam } from "../../features/DiscountPlanning/Discount";
import BlackWeekSaleSurvey from "../../components/Banners/BlackWeekSaleSurvey";

export default function DashboardPage({
  plan,
  storeUrl = "",
  appEmbedUuid = "a65575de-72bd-46c3-a21f-5b009e85bc92",
  hasCreatedFirstDiscount = false,
  hasCustomizedDesign = false,
  hasDismissedOnboarding = false,
  hasInstalledAppEmbed = false,
  hasModifiedAppBlockPlacement = false,
  hasModifiedAppEmbedFeaturedCollections = false,
}) {
  const { data: onboardingStatus, refetch: refetchOnboarding } =
    useQuery(GET_ONBOARDING_STATUS(), { fetchPolicy: "cache-and-network" }) ||
    {};
  const { data: saleStatistics } =
    useQuery(GET_SALE_STATISTICS(), { fetchPolicy: "cache-and-network" }) || {};
  const [saveOnboarding] = useMutation(SAVE_ONBOARDING_STATUS());
  const [onboardingDismissed, setOnboardingDismissed] = useState(
    hasDismissedOnboarding,
  );
  const navigate = useNavigate();
  const [showSaleTypeModal, setShowSaleTypeModal] = useState(false);

  const onFocus = () => {
    refetchOnboarding();
    window.removeEventListener("focus", onFocus);
  };

  const [steps, setSteps] = useState([
    {
      title: "Create your first discount campaign",
      description: (
        <BlockStack gap="200">
          <Text variant="bodyMd" as="p">
            Add discounts to your products and display promotional widgets like timers, announcement bars, sale badges, and sale pages.{" "}
            <a
              href="https://productessentials.helpscoutdocs.com/article/32-flash-sale-page"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more
            </a>
            </Text>
          <Box paddingBlockStart={400}><Button onClick={() => setShowSaleTypeModal(true)} variant="primary">Create campaign</Button></Box>
        </BlockStack>
      ),
      mediaSrc:
        "https://cdn.shopify.com/shopifycloud/shopify/assets/admin/home/onboarding/home-guides-offer-discount-b1e850100e6b3b773f1abf54cf50c246a59eca429e7684e189385b6752fe07a8.svg",
      completed: hasCreatedFirstDiscount,
    },
    {
      title: "Install countdown timer and announcement bar",
      description: (
        <BlockStack gap="200">
          <Text variant="bodyMd" as="p">
            To use the Disco sale widgets, enable the Disco App Embed in your store theme.
          </Text>
          <Text variant="bodyMd" as="p">
            Click the button below, then click <b>Save</b> in the next window.
          </Text>
          <Box paddingBlockStart={400}><LinkButton href={buildAppEmbedDeepLink(storeUrl, appEmbedUuid, "app_embed")} onClick={() => window.addEventListener("focus", onFocus)}>Install app embed</LinkButton></Box>
        </BlockStack>
      ),
      mediaSrc: "media/disco-app-embed.png",
      completed: hasInstalledAppEmbed,
      editable: false,
    },
    {
      title: "(Optional) Customize countdown timer and announcement bar design",
      description: (
        <BlockStack gap="200">
          <Text variant="bodyMd" as="p">
            Adjust the background color, text color, font style, and dimensions for a personalized look and feel of your sale widgets.
          </Text>
          <Box paddingBlockStart={400}>
            <Button
              url="/widgets"
              variant="primary"
              onClick={() => {
                handleCompletionToggle(2);
              }}
            >
              Customize design
            </Button>
          </Box>
        </BlockStack>
      ),
      mediaSrc:
        "https://cdn.shopify.com/shopifycloud/shopify/assets/admin/home/onboarding/detail-images/customize-theme-task-d2760705452ef967a48b5fc4a6dce1ea9fc2fe4566dea18bb0927a746abb349f.svg",
      completed: hasCustomizedDesign,
      editable: true,
    },
    {
      title: "(Optional) Reposition countdown timer and announcement bar",
      description: (
        <BlockStack gap="200">
          <Text variant="bodyMd" as="p">
            Easily move the countdown timer and announcement bar using the Store Theme Editor. Requires Online Store 2.0 themes. Not using an Online Store 2.0 theme?{" "}
            <a
              href="https://productessentials.helpscoutdocs.com/article/42-how-to-setup-custom-placement-for-countdown-timer-and-announcement-bar-in-non-online-store-2-0-compatible-shopify-themes"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more here.
            </a>
          </Text>
          <Box paddingBlockStart={400}>
            <InlineStack gap={400}>
                <LinkButton href={buildAppBlockDeepLink(storeUrl, appEmbedUuid, "countdown_timer_block")} onClick={() => handleCompletionToggle(3)}>Place countdown timer block</LinkButton>
                <LinkButton href={buildAppBlockDeepLink(storeUrl, appEmbedUuid, "announcement_bar_block", "sectionGroup:header")} onClick={() => handleCompletionToggle(3)}>Place announcement bar block</LinkButton>
            </InlineStack>
          </Box>
        </BlockStack>
      ),
      mediaSrc: "media/disco-countdown-timer-pos.png",
      completed: hasModifiedAppBlockPlacement,
      editable: true,
    },
    {
      title: "(Optional) Enable sale badges on home page collections",
      description: (
        <BlockStack gap="200">
          <Text variant="bodyMd" as="p">
            To display sale badges on featured collections, click the button below. Then, click <b>Select collections</b> from the left-hand menu to choose which collections appear on the home page.
          </Text>
          <Box paddingBlockStart={400}><LinkButton href={buildAppEmbedExpandDeepLink(storeUrl, appEmbedUuid, "app_embed")} onClick={() => handleCompletionToggle(4)}>Select featured collections</LinkButton></Box>
        </BlockStack>
      ),
      mediaSrc:
        "https://cdn.shopify.com/shopifycloud/shopify/assets/admin/home/onboarding/home-guides-offer-discount-b1e850100e6b3b773f1abf54cf50c246a59eca429e7684e189385b6752fe07a8.svg",
      completed: hasModifiedAppEmbedFeaturedCollections,
      editable: true,
    },
  ]);

  useEffect(() => {
    if (onboardingStatus) {
      const updatedSteps = [...steps];
      updatedSteps[1].completed =
        onboardingStatus.onboardingStatus.HasInstalledAppEmbed;
      setSteps(updatedSteps);
    } // eslint-disable-next-line
  }, [onboardingStatus]);

  const activeSalesCount = saleStatistics
    ? saleStatistics.saleStatistics.ActiveCount
    : "-";
  const endedSaleCount = saleStatistics
    ? saleStatistics.saleStatistics.EndedCount
    : "-";
  const scheduledSaleCount = saleStatistics
    ? saleStatistics.saleStatistics.ScheduledCount
    : "-";

  const handleCompletionToggle = (index) => {
    const updatedSteps = [...steps];
    updatedSteps[index].completed = !updatedSteps[index].completed;
    setSteps(updatedSteps);
    doSaveOnboarding(updatedSteps);
  };

  const doSaveOnboarding = async (updatedSteps, dismiss = false) => {
    try {
      await saveOnboarding({
        variables: {
          input: {
            HasCreatedFirstDiscount: updatedSteps[0].completed,
            HasCustomizedDesign: updatedSteps[2].completed,
            HasDismissedOnboarding: dismiss,
            HasInstalledAppEmbed: updatedSteps[1].completed,
            HasModifiedAppBlockPlacement: updatedSteps[3].completed,
            HasModifiedAppEmbedFeaturedCollections: updatedSteps[4].completed,
          },
        },
      });
    } catch (e) {
      console.error("failed saving onboarding: " + e);
    }
  };

  const dismissOnboarding = () => {
    setOnboardingDismissed(true);
    doSaveOnboarding(steps, true);
  };

  const handleSaleTypeSelected = (saleType) => {
    setShowSaleTypeModal(false);
    navigate(`/create/${getSaleTypeUrlParam(saleType)}`);
  };

  return (
    <Frame>
      <Page
        title="Dashboard"
        forceRender
        primaryAction={{
          content: "Create sale",
          onAction: () => setShowSaleTypeModal(true)
        }}
      >
        <Layout>
          {/*<Layout.Section>
            <Card sectioned>
              <p>Your are on the <b>{GetPlanName(plan)}</b> plan.</p>
            </Card>
          </Layout.Section>*/}
          {
            !activeSalesCount && !scheduledSaleCount && (
              <Layout.Section>
                <BlackWeekSaleSurvey />
              </Layout.Section>
            )
          }
          <Layout.Section>
            <Layout>
              <Layout.Section variant="oneThird">
                <Card padding={500}>
                  <InlineStack gap={400} align="start" wrap={false}>
                    <Icon source={DiscountsMajor} />
                    <Button
                      url="/status"
                      textAlign="left"
                      variant="plain"
                      fullWidth
                    >
                      <Text variant="bodyLg" as="p"><b>{activeSalesCount} active</b> campaign{activeSalesCount !== 1 ? "s" : ""}</Text>
                    </Button>
                  </InlineStack>
                </Card>
              </Layout.Section>
              <Layout.Section variant="oneThird">
                <Card padding={500}>
                  <InlineStack gap={400} align="start" wrap={false}>
                    <Icon source={ClockMajor} />
                    <Button
                      url="/status"
                      textAlign="left"
                      variant="plain"
                      fullWidth
                    >
                      <Text variant="bodyLg" as="p"><b>{scheduledSaleCount} scheduled</b> campaign{scheduledSaleCount !== 1 ? "s" : ""}</Text>
                    </Button>
                  </InlineStack>
                </Card>
              </Layout.Section>
              <Layout.Section variant="oneThird">
                <Card padding={500}>
                  <InlineStack gap={400} align="start" wrap={false}>
                    <Icon source={StatusActiveMajor} />
                    <Button
                      url="/status"
                      textAlign="left"
                      variant="plain"
                      fullWidth
                    >
                      <Text variant="bodyLg" as="p"><b>{endedSaleCount} ended</b> campaign{endedSaleCount !== 1 ? "s" : ""}</Text>
                    </Button>
                  </InlineStack>
                </Card>
              </Layout.Section>
            </Layout>
          </Layout.Section>
          {!onboardingDismissed && (
            <Layout.Section>
              <OnboardingComponent
                title="Setup guide"
                description="Follow this guide to integrate Disco into your store and launch your first sales campaign."
                steps={steps}
                setStepCompletion={handleCompletionToggle}
                onDismiss={dismissOnboarding}
                onSupport={openBeacon}
              />
            </Layout.Section>
          )}
          {
            false && 
              <Layout.Section>
                <CarouselCard title={<><span role="img" aria-label="gem">💎</span> Our Brilliant Partners</>} />
              </Layout.Section>
          }
          <Layout.Section>
            {
              hasInstalledAppEmbed && hasCreatedFirstDiscount && hasCustomizedDesign &&
                <FeedbackCard paddingEnd={400} />
            }
            <HelpBanner openSupportBeacon={openBeacon} />
          </Layout.Section>
        </Layout>
      </Page>
      
      <SaleTypeModal 
        open={showSaleTypeModal}
        onClose={() => setShowSaleTypeModal(false)}
        onSelect={handleSaleTypeSelected}
        plan={plan}
      />
    </Frame>
  );
}
