import { camelCaseKeys, capitalizeKeys } from "../../utils/StringUtils";
import { ProductSelectionType } from "./ProductSelectionType";

export const DiscountType = Object.freeze({
    SetConstant: 0,
    AdjustByConstant: 1,
    AdjustByPercentage: 2,
    SetToCompareAtPrice: 3
});

export const SaleTypes = Object.freeze({
    SameDiscount: 0,
    DifferentDiscounts: 1
});

export function getSaleTypeFromUrlParam(saleType) {
    return saleType === "different" ? SaleTypes.DifferentDiscounts : SaleTypes.SameDiscount;
}

export function getSaleTypeUrlParam(saleType) {
    return saleType === SaleTypes.DifferentDiscounts ? "different" : "same";
}

export function excludedOnly(filters) {
    return filters
        .filter(x => x.key === "individual_products_exclude" || x.key === "collection_exclude");
};

export function individualProductsOnly(filters) {
    return filters
        .filter(x => x.key === "individual_products_include");
};

function convertDiscountAmount(discountAmount, discountType) {
    console.log(discountAmount, discountType);
    if (discountType === DiscountType.SetConstant)
        return discountAmount;
    return discountAmount * -1;
}

export function toCsharpDiscount(
    jsDiscount,
    jsAnnouncement,
    enableComingSoonPage,
    enableSalePage,
    enableSaleTag,
    jsComingSoonPageLayout,
    jsSalePageLayout,
    saleTagLayout,
    editEntireSale,
    heroBanner,
    storeUrl) {
    const productSelection =
        jsDiscount.saleType === SaleTypes.SameDiscount
            ? jsDiscount.selectionType === ProductSelectionType.SpecificSelection
                ? jsDiscount.filters
                : excludedOnly(jsDiscount.filters)
            : individualProductsOnly(jsDiscount.filters);

    const productDiscountMapping =
        jsDiscount.saleType === SaleTypes.SameDiscount
            ? []
            : jsDiscount.productDiscountMapping.map((x) => ({
                ProductId: x.id,
                ApplicableVariants: x.applicableVariants ? x.applicableVariants : [],
                Action: x.discountType,
                Value: convertDiscountAmount(x.discountAmount, x.discountType),
            }));

    const comingSoonPageLayout = jsComingSoonPageLayout ? capitalizeKeys({
        ...jsComingSoonPageLayout,
        buttons: jsComingSoonPageLayout.buttons ? jsComingSoonPageLayout.buttons.map(capitalizeKeys) : []
    }) : jsComingSoonPageLayout;

    const salePageLayout = jsSalePageLayout ? capitalizeKeys({
        ...jsSalePageLayout,
        buttons: jsSalePageLayout.buttons ? jsSalePageLayout.buttons.map(capitalizeKeys) : [],
        productsOrdered: jsSalePageLayout.productsOrdered 
            ? jsSalePageLayout.productsOrdered.map(x => x.id) 
            : []
    }) : jsSalePageLayout;

    return {
        Title: jsDiscount.title,
        StoreUrl: storeUrl,
        SaleType: jsDiscount.saleType,
        ProductDiscountMapping: productDiscountMapping,
        Action: {
            Action: jsDiscount.discountType,
            Value: convertDiscountAmount(jsDiscount.discountAmount, jsDiscount.discountType),
            ActivateCountdown: jsDiscount.createCountdown && jsDiscount.endDate ? true : false,
            CreateCollection: jsDiscount.createCollection,
            RoundToNearest: jsDiscount.discountRoundToNearest,
            RoundToNearestValue: jsDiscount.discountRoundToNearestValue
                ? jsDiscount.discountRoundToNearestValue / 100
                : 0,
            EnableRemoveTagWhenActive: (!!jsDiscount.removeTagText) ? jsDiscount.enableRemoveTag : false,
            EnableAddTagWhenActive: (!!jsDiscount.addTagText) ? jsDiscount.enableAddTag : false,
            RemoveTagWhenActive: jsDiscount.removeTagText,
            AddTagWhenActive: jsDiscount.addTagText
        },
        ProductSelection: productSelection,
        ProductSelectionType: parseInt(
            jsDiscount.saleType === SaleTypes.SameDiscount
                ? jsDiscount.selectionType
                : ProductSelectionType.SpecificSelection
        ),
        StartAt:
            jsDiscount.startDate !== null
                ? jsDiscount.startDate.toISOString()
                : new Date(0).toISOString(),
        EndAt:
            jsDiscount.endDate !== null
                ? jsDiscount.endDate.toISOString()
                : new Date(0).toISOString(),
        Announcement: capitalizeKeys(jsAnnouncement),
        EnableSaleTag: enableSaleTag,
        EnableComingSoonPage: enableComingSoonPage,
        EnableSalePage: enableSalePage,
        ComingSoonPageLayout: comingSoonPageLayout,
        SalePageLayout: salePageLayout,
        SaleTagLayout: capitalizeKeys(saleTagLayout),
        ExcludeInactiveProducts: jsDiscount.excludeInactiveProducts,
        Deactivate: editEntireSale,
        HeroBanner: heroBanner ? {
            Enabled: heroBanner.enabled,
            ShowOnCollection: heroBanner.showOnCollection,
            ShowOnHomepage: heroBanner.showOnHomepage,
            SelectedCollection: heroBanner.selectedCollection,
            Settings: capitalizeKeys(heroBanner.settings)
        } : null
    };
};

export function toJsDiscount(csharpDiscount) {
    const filters = csharpDiscount.Filters.map(keysToLowerCase);
    console.log(csharpDiscount);
    const productDiscountMapping = csharpDiscount.ProductDiscountMapping && csharpDiscount.ProductDiscountMapping.map((obj) => ({
        id: obj.ProductId,
        applicableVariants: obj.ApplicableVariants,
        discountType: Number(obj.Action),
        discountAmount: convertDiscountAmount(obj.Value, Number(obj.Action)),
        title: null,
        price: null,
        featuredImage: null
    }));

    const comingSoonPageLayout = csharpDiscount.ComingSoonPageLayout ? camelCaseKeys({
        ...csharpDiscount.ComingSoonPageLayout,
        Buttons: csharpDiscount.ComingSoonPageLayout.Buttons ? csharpDiscount.ComingSoonPageLayout.Buttons.map(camelCaseKeys) : [],
    }) : csharpDiscount.ComingSoonPageLayout;

    const salePageLayout = csharpDiscount.SalePageLayout ? camelCaseKeys({
        ...csharpDiscount.SalePageLayout,
        Buttons: csharpDiscount.SalePageLayout.Buttons ? csharpDiscount.SalePageLayout.Buttons.map(camelCaseKeys) : [],
        ProductsOrdered: csharpDiscount.SalePageLayout.ProductsOrdered 
            ? csharpDiscount.SalePageLayout.ProductsOrdered.map(x => { return { id: x } }) 
            : []
    }) : csharpDiscount.SalePageLayout;

    return {
        title: csharpDiscount.Title,
        saleType: csharpDiscount.SaleType,
        productDiscountMapping: productDiscountMapping,
        createCollection: csharpDiscount.Action.CreateCollection,
        createCountdown: csharpDiscount.Action.ActivateCountdown,
        discountType: csharpDiscount.Action.Action,
        discountAmount: convertDiscountAmount(csharpDiscount.Action.Value, csharpDiscount.Action.Action),
        discountRoundToNearest: csharpDiscount.Action.RoundToNearest,
        discountRoundToNearestValue: csharpDiscount.Action.RoundToNearestValue * 100,
        enableAddTag: csharpDiscount.Action.EnableAddTagWhenActive,
        addTagText: csharpDiscount.Action.AddTagWhenActive,
        enableRemoveTag: csharpDiscount.Action.EnableRemoveTagWhenActive,
        removeTagText: csharpDiscount.Action.RemoveTagWhenActive,
        filters: filters,
        selectionType: csharpDiscount.ProductSelectionType.toString(),
        announcement: camelCaseKeys(csharpDiscount.Announcement),
        startDate: new Date(csharpDiscount.StartDate),
        endDate: new Date(csharpDiscount.EndDate),
        status: csharpDiscount.Status,
        excludeInactiveProducts: csharpDiscount.ExcludeInactiveProducts,
        enableSaleTag: csharpDiscount.EnableSaleTag,
        enableSalePage: csharpDiscount.EnableSalePage,
        enableComingSoonPage: csharpDiscount.EnableComingSoonPage,
        saleTagLayout: camelCaseKeys(csharpDiscount.SaleTagLayout),
        salePageLayout: salePageLayout,
        comingSoonPageLayout: comingSoonPageLayout,
        heroBanner: csharpDiscount.HeroBanner ? {
            enabled: csharpDiscount.HeroBanner.Enabled,
            showOnCollection: csharpDiscount.HeroBanner.ShowOnCollection,
            showOnHomepage: csharpDiscount.HeroBanner.ShowOnHomepage,
            selectedCollection: camelCaseKeys(csharpDiscount.HeroBanner.SelectedCollection),
            settings: camelCaseKeys(csharpDiscount.HeroBanner.Settings)
        } : null
    };
}

function keysToLowerCase(obj) {
    const newObj = Object.fromEntries(Object.entries(obj).map(([key, val]) =>
        [key.toLowerCase(), val]));
    return newObj;
}

export const DiscountDefaultSettings = {
    title: "",
    createCollection: false,
    createCountdown: false,
    discountType: DiscountType.AdjustByPercentage,
    discountAmount: "",
    discountRoundToNearest: false,
    discountRoundToNearestValue: 99,
    enableAddTag: false,
    addTagText: "on-disco-sale",
    enableRemoveTag: false,
    removeTagText: "not-on-disco-sale",
    filters: [],
    saleType: SaleTypes.SameDiscount,
    productDiscountMapping: [],
    selectionType: ProductSelectionType.Storewide,
    startDate: null,
    endDate: null
};