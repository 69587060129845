import React, { useState, useRef } from "react";
import { Popover, Icon, Button } from "@shopify/polaris";
import { LockMajor, LockMinor } from "@shopify/polaris-icons";
import { hasAccess } from "../../utils/FeatureAccess";
import { GetPlanName, SubscriptionPlan } from "../../pages/SubscriptionPage/SubscriptionPlan";
import { getFeaturePrettyName } from "../../utils/FeatureAccess";

const UpgradeLock = ({ feature, currentPlan, small = false }) => {
  const [popoverActive, setPopoverActive] = useState(false);
  const cancelCloseTimeout = useRef(null);

  const openPopover = () => {
    clearTimeout(cancelCloseTimeout.current);
    setPopoverActive(true);
  };

  const startCloseTimer = () => {
    cancelCloseTimeout.current = setTimeout(() => {
      setPopoverActive(false);
    }, 500);
  };

  const activator = (
    <div onMouseEnter={openPopover} onMouseLeave={startCloseTimer}>
      <Button variant="plain">
        <Icon source={small ? LockMinor : LockMajor} tone="base" />
      </Button>
    </div>
  );

  const nextAvailablePlan = hasAccess(feature, currentPlan) ? null : 
    GetPlanName(Object.values(SubscriptionPlan).find(plan => hasAccess(feature, plan) && plan > currentPlan && !GetPlanName(plan).startsWith('Legacy')));

  return (
    <div style={{ position: "relative" }}>
      <Popover
        active={popoverActive}
        activator={activator}
        onClose={startCloseTimer}
      >
        <div onMouseEnter={openPopover} onMouseLeave={startCloseTimer}>
          <Popover.Pane fixed>
            <Popover.Section>
              {nextAvailablePlan ? 
                `Upgrade to ${nextAvailablePlan} to get access to ${getFeaturePrettyName(feature)}.` :
                `This feature is not available on your current plan.`
              }
              {" "}
              <Button url="/subscription" variant="plain">
                Upgrade
              </Button>
            </Popover.Section>
          </Popover.Pane>
        </div>
      </Popover>
    </div>
  );
};

export default UpgradeLock;
