import { SubscriptionPlan } from "../pages/SubscriptionPage/SubscriptionPlan";

export const FeatureAccess = {
  flashSalePage: [
    SubscriptionPlan.LegacyBuilder,
    SubscriptionPlan.LegacyGrower,
    SubscriptionPlan.LegacyScaler,
    SubscriptionPlan.Professional,
    SubscriptionPlan.Elite,
    SubscriptionPlan.Unlimited
  ],
  saleBadges: [
    SubscriptionPlan.LegacyBuilder,
    SubscriptionPlan.LegacyGrower,
    SubscriptionPlan.LegacyScaler,
    SubscriptionPlan.Lite,
    SubscriptionPlan.Professional,
    SubscriptionPlan.Elite,
    SubscriptionPlan.Unlimited
  ],
  differentDiscounts: [
    SubscriptionPlan.LegacyBuilder,
    SubscriptionPlan.LegacyGrower,
    SubscriptionPlan.LegacyScaler,
    SubscriptionPlan.Professional,
    SubscriptionPlan.Elite,
    SubscriptionPlan.Unlimited
  ],
  excludeProducts: [
    SubscriptionPlan.LegacyBuilder,
    SubscriptionPlan.LegacyGrower,
    SubscriptionPlan.LegacyScaler,
    SubscriptionPlan.Lite,
    SubscriptionPlan.Professional,
    SubscriptionPlan.Elite,
    SubscriptionPlan.Unlimited
  ],
  excludeCollections: [
    SubscriptionPlan.LegacyBuilder,
    SubscriptionPlan.LegacyGrower,
    SubscriptionPlan.LegacyScaler,
    SubscriptionPlan.Lite,
    SubscriptionPlan.Professional,
    SubscriptionPlan.Elite,
    SubscriptionPlan.Unlimited
  ],
  autoTagProducts: [
    SubscriptionPlan.LegacyBuilder,
    SubscriptionPlan.LegacyGrower,
    SubscriptionPlan.LegacyScaler,
    SubscriptionPlan.Professional,
    SubscriptionPlan.Elite,
    SubscriptionPlan.Unlimited
  ],
  advancedAnnouncement: [
    SubscriptionPlan.LegacyBuilder,
    SubscriptionPlan.LegacyGrower,
    SubscriptionPlan.LegacyScaler,
    SubscriptionPlan.Lite,
    SubscriptionPlan.Professional,
    SubscriptionPlan.Elite,
    SubscriptionPlan.Unlimited
  ],
  // Add more features and their corresponding plans here
};

export function hasAccess(feature, currentPlan) {
  return FeatureAccess[feature] && FeatureAccess[feature].includes(currentPlan);
}

const FeaturePrettyNames = {
  flashSalePage: "Flash Sale Page",
  saleBadges: "Sale Badges",
  differentDiscounts: "Different Discounts",
  excludeProducts: "Exclude Products",
  excludeCollections: "Exclude Collections",
  autoTagProducts: "Auto-Tag Products",
  advancedAnnouncement: "Advanced Announcement",
  // Add more feature pretty names here
};

export function getFeaturePrettyName(feature) {
  return FeaturePrettyNames[feature] || feature;
}
