export const openBeacon = () => {
    openBeaconCrisp();
    openBeaconHelpscout();
};

export const setBeaconStore = (storeUrl) => {
    setBeaconStoreCrisp(storeUrl);
    setBeaconStoreHelpscout(storeUrl);
    setMouseflowStore(storeUrl);
};

const setBeaconStoreCrisp = (storeUrl) => {
    const interval = setInterval(() => {
        if (typeof window.$crisp === 'object' && typeof window.$crisp.push === 'function') {
            window.$crisp.push(["set", "session:data", ["shopify-store-url", storeUrl]]);
            clearInterval(interval);
        }
    }, 2000);

    // Clear interval after 60 seconds if not successful
    setTimeout(() => {
        clearInterval(interval);
        if (typeof window.$crisp !== 'object' || typeof window.$crisp.push !== 'function') {
            console.warn('Crisp function not available after timeout');
        }
    }, 60000);
};

const setBeaconStoreHelpscout = (storeUrl) => {
    if (typeof window.Beacon === 'function') {
        window.Beacon('identify', { 'shopify-store-url': storeUrl });
    } else {
        console.warn('Beacon function not available');
    }
};

const setMouseflowStore = (storeUrl) => {
    window._mfq = window._mfq || [];
    window._mfq.push(["setVariable", "store", storeUrl]);
};

const openBeaconCrisp = () => {
    if (typeof window.$crisp === 'object' && typeof window.$crisp.push === 'function') {
        window.$crisp.push(["do", "chat:open"])
    } else {
        console.warn('Crisp function not available');
    }
};

const openBeaconHelpscout = () => {
    if (typeof window.Beacon === 'function') {
        window.Beacon('open');
    } else {
        console.warn('Beacon function not available');
    }
};