import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import Config from "./utils/Config";
import { camelCaseKeys } from "./utils/StringUtils";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { SubscriptionPlan } from "./pages/SubscriptionPage/SubscriptionPlan";
import axios from "axios";
import * as serviceWorker from "./serviceWorker";

const authQueryString = new URLSearchParams(window.location.search).toString();
const installUrlAws = `${Config.apiGateway.URL}/authentication/install/${Config.appName}?${authQueryString}`;
const installUrlFly = `${Config.flyApi.URL}/install/${Config.appName}?${authQueryString}`;

Sentry.init({
  dsn: Config.sentryDsn,
  integrations: [new BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

const authenticate = async () => {
  try {
    // Try Fly.io first
    try {
      const { data, status } = await axios.get(installUrlFly);
      if (status === 200) {
        handleSuccessfulAuth(data);
        return;
      }
    } catch (flyError) {
      console.log("Fly.io authentication failed, falling back to AWS:", flyError);
    }

    // Fallback to AWS
    const { data, status } = await axios.get(installUrlAws);
    if (status === 200) {
      handleSuccessfulAuth(data);
    } else {
      throw new Error(`Unexpected status code: ${status}`);
    }
  } catch (error) {
    console.error("Authentication failed:", error);
    document.getElementById("root").innerText = "Authentication failed, please try again";
  }
};

// Helper function to handle successful authentication
const handleSuccessfulAuth = (data) => {
  const { OnboardingStatus, Plan, FreeDiscountsLeft, NeedsVatNumber, MissingScopes, RedirectUrl } = data;
  const commonProps = {
    plan: Plan,
    freeDiscountsLeft: FreeDiscountsLeft,
    requestVatNumber: NeedsVatNumber,
    missingScopes: MissingScopes,
    onboardingStatus: camelCaseKeys(OnboardingStatus),
  };
  
  if (Plan >= SubscriptionPlan.LegacyStarter) {
    ReactDOM.render(<App {...commonProps} />, document.getElementById("root"));
  } else {
    window.top === window.self
      ? window.location.assign(RedirectUrl)
      : ReactDOM.render(<App {...commonProps} redirectUrl={RedirectUrl} />, document.getElementById("root"));
  }
};

if (!authQueryString) {
  document.getElementById("root").innerText = "Cannot authenticate, please reload app";
} else {
  authenticate();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
