import React from "react";
import {
  Modal,
  Text,
  BlockStack,
  ResourceList,
  ResourceItem,
  Icon,
  InlineStack,
  Box,
} from "@shopify/polaris";
import { ProductsMajor, CollectionsMajor, ChevronRightMinor } from "@shopify/polaris-icons";
import { SaleTypes } from "../../features/DiscountPlanning/Discount";
import { hasAccess } from "../../utils/FeatureAccess";
import UpgradeLock from "../UpgradeLock/UpgradeLock";

export default function SaleTypeModal({ open, onClose, onSelect, plan }) {
  const hasDifferentDiscountsAccess = hasAccess("differentDiscounts", plan);

  const saleTypeOptions = [
    {
      id: SaleTypes.SameDiscount,
      icon: ProductsMajor,
      title: "Same discount",
      description: "Apply the same discount to all selected products.",
      example:
        "Example: Apply a 20% discount to all selected items in the 'Summer Sale' collection.",
      disabled: false,
    },
    {
      id: SaleTypes.DifferentDiscounts,
      icon: CollectionsMajor,
      title: "Different discounts",
      description: "Set different discounts for each selected product.",
      example:
        "Example: Set a $10 discount on selected jackets and 15% off selected shoes.",
      disabled: !hasDifferentDiscountsAccess,
    },
  ];

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Choose discount campaign type"
      titleHidden={false}
    >
      <ResourceList
        items={saleTypeOptions}
        renderItem={(item) => (
          <ResourceItem
            id={item.id}
            onClick={() => !item.disabled && onSelect(item.id)}
            media={<Icon source={item.icon} />}
            disabled={item.disabled}
          >
            <Box paddingInlineStart={100}>
              <InlineStack gap="400" align="space-between" blockAlign="center">
                <BlockStack gap="100">
                  <Text variant="headingMd" as="h3">
                    {item.title}
                  </Text>
                  <Text variant="bodyMd" as="p">
                    {item.description}
                  </Text>
                  <Text variant="bodySm" tone="subdued">
                    {item.example}
                  </Text>
                </BlockStack>
                {!item.disabled && (
                    <Box>
                      <Icon source={ChevronRightMinor} tone="subdued" />
                    </Box>
                  )}
                {item.disabled && (
                  <UpgradeLock
                    feature="differentDiscounts"
                    currentPlan={plan}
                  />
                )}
              </InlineStack>
            </Box>
          </ResourceItem>
        )}
      />
    </Modal>
  );
}
