import React from "react";
import PropTypes from 'prop-types';
import { Button, BlockStack, Banner, InlineStack } from "@shopify/polaris";
import { getWithExpiry, setWithExpiry } from "../../utils/LocalStorageExpiry";
import { useNavigate } from "@shopify/app-bridge-react";
import { useMutation } from "@apollo/client";
import { SET_FEEDBACK } from "../../graphql/Mutations/Settings";

const FeedbackButton = ({ icon, title, description, onClick, variant }) => (
  <Button
    fullWidth
    textAlign="left"
    onClick={onClick}
    variant={variant}
  >
    <BlockStack gap={200} align="start">
      <div>
        <span role="img" aria-label={icon.label}>{icon.emoji}</span>{' '}
        <b>{title}</b>
      </div>
      <div>{description}</div>
    </BlockStack>
  </Button>
);

const BlackWeekSaleSurvey = ({ onDismiss = () => {} }) => {
  const navigate = useNavigate();
  const [setFeedback, { loading }] = useMutation(SET_FEEDBACK(), {
    onError: (error) => {
      console.error('Failed to submit feedback:', error);
      // You might want to show an error message to the user here
    }
  });

  const localStorageKey = `dismissed_BlackWeekSaleSurvey`;
  const [isDismissed, setIsDismissed] = React.useState(() => {
    try {
      return JSON.parse(getWithExpiry(localStorageKey)) || false;
    } catch (error) {
      console.error('Failed to parse localStorage value:', error);
      return false;
    }
  });

  const handleDismiss = React.useCallback(() => {
    setIsDismissed(true);
    try {
      setWithExpiry(localStorageKey, "true", 1000 * 60 * 60 * 4320);
      onDismiss();
    } catch (error) {
      console.error('Failed to set localStorage value:', error);
    }
  }, [localStorageKey, onDismiss]);

  React.useEffect(() => {
    // This effect runs once on mount and never again (empty dependency array)
    // Check if the dismissed state is already in localStorage
    const dismissed = getWithExpiry(localStorageKey);
    if (dismissed) {
      setIsDismissed(JSON.parse(dismissed));
    }
  }, [localStorageKey]);

  if (isDismissed) return null;

  return (
    <Banner tone="success" title="🎉 Black Week Sale Survey 🎉" hideIcon>
      <p>Another successful Black Week in the books! <span role="img" aria-label="fireworks">🎉</span></p>
      <p>As the holiday season continues...</p>
      <p>
        We'd love to hear -{' '}
        <b>how did Disco and our support team help you during the sales period?</b>
      </p>

      <div style={{ maxWidth: "680px", width: "100%" }}>
        <InlineStack gap="200" wrap={false}>
          <FeedbackButton
            icon={{ emoji: '👍', label: 'thumbs up' }}
            title="It Was Great!"
            description="The sale ran smoothly and support was helpful."
            onClick={() => {
              setFeedback({ variables: { feedback: "bfcm-2024-great" } });
              handleDismiss();
              navigate("/review");
            }}
            variant="primary"
            disabled={loading}
          />
          <FeedbackButton
            icon={{ emoji: '👎', label: 'thumbs down' }}
            title="I Had Issues"
            description="I had challenges I was unable to solve."
            onClick={() => {
              if (window.Beacon) {
                window.Beacon("show-message", "7f7c564e-9dc6-4f38-8f86-dd869ab3b425");
                setFeedback({ variables: { feedback: "bfcm-2024-bad" } });
                handleDismiss();
              }
            }}
            disabled={loading}
          />
        </InlineStack>
      </div>
    </Banner>
  );
};

BlackWeekSaleSurvey.propTypes = {
  onDismiss: PropTypes.func
};

export default BlackWeekSaleSurvey;
